
import { useNavigate } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react'

import { useSelector } from "react-redux"
import { selectCurrentToken } from "../slices/authSlice"

import logo from '../../../assets/logo.png'

const Success = () => {
 

    const succRef = useRef()
    const [succMsg, setSuccMsg] = useState('')
    const navigate = useNavigate()

    const token = useSelector(selectCurrentToken)

    useEffect(() => {
      if(token){
        navigate('/')
      }else{
      succRef.current.focus()
      }
    }, [])

    useEffect(() => {
        setSuccMsg('Account activated successfully.')
    }, [])

  
    const content = (
   
      <div className="auth-wrapper">
         <div className="auth-inner">
      <section>
        <h3> <img src={logo} alt="Logo" style={{ height: 60, width: 150}} /></h3>
      <form >
        <p style={{color:'green'}} ref={succRef} className={succMsg ? "errmsg" : "offscreen"} aria-live="assertive">{succMsg}</p>
      </form>
  </section>
  </div></div>
    )

    return content
}
export default Success