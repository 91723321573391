
import { useRef,useState, useEffect, React} from 'react'
import { useGetUsersMutation,useGetCompaniesMutation,useGetRolesMutation,useEditUserMutation,useRegisterMutation } from "../slices/userApiSlice"
import { useSelector } from "react-redux"
import {selectCurrentUserRoles /*, selectCurrentUserPermissions*/ }from '../../auth/slices/authSlice'
import { Col, Row,Button,message,Descriptions, Form , Input, Select, Table, Popconfirm,Cascader,Modal } from 'antd';
import {
  EditOutlined
} from '@ant-design/icons';

// import { useRegisterMutation } from '../../auth/slices/authApiSlice'
function UsersList() {

  const [open, setOpen] = useState(false);

  const emailRegRef = useRef()
  const errRef = useRef()
  const succRef = useRef()
  
  
  const [succMsg, setSuccMsg] = useState('')

  const [emailReg, setEmailReg] = useState('')
  const [pwd, setPwd] = useState('')
  const [rePwd, setRePwd] = useState('')
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [errMsg, setErrMsg] = useState('')
  // const navigate = useNavigate()

  // const token = useSelector(selectCurrentToken)

  const [register] = useRegisterMutation()
  //const [login] = useLoginMutation()
  //const dispatch = useDispatch()

  // useEffect(() => {
  //   if(token){
  //     navigate('/')
  //   }else{
  //   emailRef.current.focus()
  //   }
  // }, [])

  // useEffect(() => {
  //   setErrMsg('')
  // }, [email, pwd,rePwd,lastName,firstName])

  const validate = () => {
    
    let isValid = true;

    if (pwd !== "undefined") {
          
      var pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      if (!pattern.test(pwd)) {
        isValid = false;
        setErrMsg("Password is not strong enough. Password must be 8 charahters long or more with at least one upper case letter and one special character"); 
      }
    }
    
    if (typeof pwd !== "undefined" && rePwd !== "undefined") {
        
      if (pwd != rePwd) {
        isValid = false;
        setErrMsg("Passwords don't match."); 
      }
    } 

    return isValid;
}

const showModal =  () => {
  setOpen(true);
}

const handleCancel = () => {
  setOpen(false);
};

  const handleSubmit = async (e) => {
  e.preventDefault()
  setSuccMsg('')
  setErrMsg('')
  if(validate()){
  try {
      const regData = await register({ Username:`${firstName}.${lastName}`, Email:emailReg, Password:pwd }).unwrap()

      if (regData){
     // const userData = await login({ Email:email, Password:pwd }).unwrap()
     // dispatch(setCredentials({ ...userData, email }))
     // console.log(userData)
     setSuccMsg('Account created successfully. An email is being sent to activate the account.')
     setErrMsg('')
     succRef.current.focus();
      }
      
      
      setEmailReg('')
      setPwd('')
      setRePwd('')
      setLastName('')
      setFirstName('')
     // navigate('/')
  } catch (err) {
      if (!err?.originalStatus) {
          // isLoading: true until timeout occurs
          setErrMsg('No Server Response');
      } else if (err.originalStatus === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.originalStatus === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Login Failed');
      }
      errRef.current.focus();
  }
}
}

  const handleEmailRegInput = (e) => setEmailReg(e.target.value)

  const handlePwdInput = (e) => setPwd(e.target.value)

  const handleRePwdInput = (e) => setRePwd(e.target.value)

  const handleFirstNameInput = (e) => setFirstName(e.target.value)

  const handleLastNameInput = (e) => setLastName(e.target.value)

  const roles = useSelector(selectCurrentUserRoles);
  const allowedRoles = (checkRoles) => {
      return roles?.find(role => checkRoles?.includes(role))
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'userName',
      sorter: true,
      width: '20%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '20%',
    },
    {
      title: 'Company - Line of businesses',
      dataIndex: 'company',
      render: (_, record) => (`${record.company.name ? record.company.name : ""} - ${record.lineOfBusinesses.map(x=>x.name).join()}`),
      width: '30%',
    },
    {
      title: 'Roles',
      dataIndex: 'userRoles',
      render: (_, record) => (record.userRoles.join()),
      width: '20%',
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      render: (isActive) => (isActive? "Yes" : "No"),
    },
    {
      title: 'Action',
      dataIndex: 'i',
      key: 'i',
      render: (_, record) =>  (allowedRoles(['SuperAdmin','FDBAdmin','CompanyAdmin']) ? <>
       <Popconfirm title={record.isActive? "sure to deactivate?" : "sure to activate?"} onConfirm={() => editClick(record.id,!record.isActive)}>
       <Button disabled={actionLoading} type="link" htmlType="button" danger >
       <EditOutlined />{record.isActive? "Deactivate" : "Activate"}
       </Button>
       </Popconfirm>
       </>:null),
    },
  ];


  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    showSizeChanger: true,
    pageSizeOptions: [1,5,10,20,50,100],
    position: 'bottomCenter'
  });

  const key = 'updatable';

  const [actionLoading, setActionLoading] = useState(false)

const [getUsers, { isLoading }] = useGetUsersMutation();
const [getCompanies] = useGetCompaniesMutation();
const [getRoles] = useGetRolesMutation();
const [editUser] = useEditUserMutation()

const [users, setUsers] = useState([])

const [companiesOptions, setCompaniesOptions] = useState([])
const [companies, setCompanies] = useState([])

const [rolesOptions, setRolesOptions] = useState([])

//const emailRef = useRef()
const [email, setEmail] = useState('')
const [selectedLOBs, setSelectedLOBs] = useState([])
const [selectedCompanies, setSelectedCompanies] = useState([])
const [selectedRoles, setSelectedRoles] = useState([])


const handleEmailInput =  async (e) => {
  setEmail(e.target.value)
  if(e.target.value.length>3){
    loadUsers(e.target.value,1,pagination.pageSize,selectedRoles, selectedLOBs, selectedCompanies)
    //setUsers( await getUsers({ emailOrUserName: e.target.value, Page:1, PageSize:pageSize,roles:role != '' ? [role] :[], lineOfBusinessesIds:companies, companiesIds:companies.length>0?[companies[0]]:[]}).unwrap());
  }else{
    loadUsers("",1,pagination.pageSize,selectedRoles, selectedLOBs, selectedCompanies)
    //setUsers( await getUsers({ Page:1, PageSize:pageSize,roles:role != '' ? [role] :[], lineOfBusinessesIds:companies, companiesIds:companies.length>0?[companies[0]]:[]}).unwrap());
  }
}


const handleChangeCompanies =  async (value) => {
  // searchInput.current.querySelector("input").value = "";
 // setCompanies(args[0])
 // setUsers( await getUsers({ emailOrUserName: email, Page:1, PageSize:pageSize, roles:role != '' ? [role] :[], lineOfBusinessesIds:args[0], companiesIds:args[0].length>0?[args[0][0]]:[] }).unwrap());

  console.log(value)
  if (value !=null && value.length>0){
    var lobIds = []
    var compIds = []
    value.forEach(element => {
      if(element.length==1){
        companies.find(x=>x.id==element[0]).lineOfBusinesses.map(x=>x.id).forEach( item =>{
          lobIds.push(item)
        })
        compIds.push(element[0])
      }else{
        element.forEach((item, index) => {
          if(index==0){
            compIds.push(item)
            
          } else{
            lobIds.push(item)
          }
        });
      }
      
    });
  setSelectedCompanies(compIds)
  setSelectedLOBs(lobIds)
  loadUsers(email,1, pagination.pageSize,selectedRoles,lobIds,compIds)
  }else{
    setSelectedCompanies([])
    setSelectedLOBs([])
    loadUsers(email,1, pagination.pageSize,selectedRoles,[],[])
  }
};

const handleChangeRoles =  async (value) => {
 // setRole(args[0])
 // setUsers( await getUsers({ emailOrUserName: email, Page:1, PageSize:pageSize, roles:args[0] != '' ? [args[0]] :[], lineOfBusinessesIds:companies, companiesIds:companies.length>0?[companies[0]]:[]}).unwrap());
  var roles = []
  value.forEach(element => {
    roles.push(element[0])
  });
  setSelectedRoles(roles)
  loadUsers(email,1, pagination.pageSize,roles,selectedLOBs,selectedCompanies)
};


const loadUsers = async (email,page,pageSize,roles,lineOfBusinessesIds,companiesIds) =>{

  var usersArray = await getUsers({ emailOrUserName: email, page, pageSize, roles, lineOfBusinessesIds, companiesIds}).unwrap()
  var total = usersArray != null && usersArray.length>0 ? usersArray[0].totalCount : 0
  console.log(usersArray)
  setUsers(usersArray)

  reSetPagingInfo(page,pageSize,total)
  
}

const reSetPagingInfo = (page,pageSize,total)=>{
  const paginationObject = {...pagination}
  paginationObject.current =page
  paginationObject.pageSize =pageSize
  paginationObject.total =total

  setPagination(paginationObject);
}


const handleTableChange = (newPagination, filters, sorter) => {
  loadUsers(email,newPagination.current,newPagination.pageSize,selectedRoles,selectedLOBs,selectedCompanies)
};


useEffect( () =>{ 
  async function fetchMyAPI() {
   
  //  setUsers(await getUsers({Page:1, PageSize:pageSize }).unwrap());

    loadUsers("",1,pagination.pageSize,[],[],[])

    var companiesWithLOBs = await getCompanies({}).unwrap();
    setCompanies(companiesWithLOBs)
    transformCompanies(companiesWithLOBs)

    var roles = await getRoles().unwrap();
    
    transformRoles(roles)
    
  }
  fetchMyAPI()
  
}, [])



const transformRoles = (roles) =>{
  var rolesArray = []
  console.log(roles)
  roles.roles.forEach((element, index) => {
    rolesArray.push({label: element, value: element})
  });
  setRolesOptions(rolesArray)
}

const transformCompanies = (comapniesArray) =>{
  var lineOfBusinessesArray = []
  comapniesArray.forEach((company) => {
    if(company.lineOfBusinesses.length>0){
      lineOfBusinessesArray.push({label:company.name,value:company.id,children:
        returnLineOfBusinesses(company.name,company.lineOfBusinesses)
      })
    }else{
      lineOfBusinessesArray.push({label:company.name,value:company.id,disabled:true
      })
    }
  });
  setCompaniesOptions(lineOfBusinessesArray)
}

const returnLineOfBusinesses =    (companyName,lineOfBusinesses) => {
  var lineOfBusinessesArray = []
  lineOfBusinesses.forEach(element => {
    lineOfBusinessesArray.push({label:element.name,value:element.id})
  });
  return lineOfBusinessesArray
}

const filter = (inputValue, path) =>
  path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const editClick = async (id,isActive) => {

    setActionLoading(true)
    showLoadingMessage('loading...')
      await editUser({Id: id,IsActive:isActive}).unwrap()
      showMessage(isActive?'User activated successfully!': 'User deactivated successfully!')
      loadUsers(email, pagination.current, pagination.pageSize,selectedRoles,selectedLOBs,selectedCompanies)
      setActionLoading(false)
    }

    const showLoadingMessage  = (msg)=>{
      message.loading({
        content: msg,
        key,
        className: 'custom-class',
        style: {
          marginTop: '8vh',
        },
      });
    }
  
    const showMessage = (msg)=>{
      setTimeout(() => {
      message.success({
        content: msg,
        key,
        className: 'custom-class',
        style: {
          marginTop: '8vh',
        },
      });
    }, 500);
    }

    const CreateUser =() =>{

    }


  return (
   <>
   <Descriptions title="Manage Users"></Descriptions>
        
            <div
          className="site-layout-background"
          style={{
            padding: 24,
            minHeight: 150,
          }}
        >
      
      <Descriptions title="Users list"></Descriptions>

     

      <div  style={{paddingLeft:15}}>
      <Form
       
      name="search"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
    >
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
        <Col className="gutter-row" span={6}>
        <Form.Item>
        <Input name="email"
            onChange={handleEmailInput}
            autoComplete="off" placeholder="Search with user name or email" 
            />
            </Form.Item>
        
          </Col>

          <Col className="gutter-row" span={6}>
          <Form.Item>
        
        <Cascader
   options={rolesOptions}
   onChange={handleChangeRoles}
   placeholder="Search with role"
   showSearch={{
     filter,
   }}
   multiple
   maxTagCount="responsive"
   onSearch={(value) => console.log(value)}
 />
  </Form.Item>
  </Col>

         <Col className="gutter-row" span={8}>
        <Form.Item>
        <Cascader
   options={companiesOptions}
   onChange={handleChangeCompanies}
   placeholder="Search with company or line of business"
   showSearch={{
     filter,
   }}
   multiple
   maxTagCount="responsive"
   onSearch={(value) => console.log(value)}
 />
  </Form.Item>

 
        
      </Col> 
      <Button
             onClick={showModal}
             type="primary"
            >
              Create user
            </Button>
      </Row>
      </Form>
 
  
 <Table
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={users}
      pagination={pagination}
      loading={isLoading}
      onChange={handleTableChange}
      size="default"
      rowClassName="editable-row"
    />
    </div>
  
</div>
<Modal
        visible={open}
        title="Create user"
        onOk={handleSubmit}
        onCancel={handleCancel}
        width='400px'
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        
        ]}
      >
       <form onSubmit={handleSubmit}>

<div className="mb-3">
  <label>First name</label>
  <input
    type="text"
    className="form-control"
    placeholder="First name"
    id="firstName"
    value={firstName}
    onChange={handleFirstNameInput}
    autoComplete="off"
    required
  />
</div>
<div className="mb-3">
  <label>Last name</label>
  <input type="text" 
  className="form-control" 
  placeholder="Last name" 
  id="lastName"
    value={lastName}
    onChange={handleLastNameInput}
    autoComplete="off"
    required
  />
</div>
<div className="mb-3">
  <label>Email address</label>
  <input
   type="email"
   id="email"
   ref={emailRegRef}
   value={emailReg}
   onChange={handleEmailRegInput}
   autoComplete="off"
   required
   className="form-control"
   placeholder="Enter email"
  />
</div>
<div className="mb-3">
  <label>Password</label>
  <input
    type="password"
    className="form-control"
    placeholder="Enter password"
    id="password"
    value={pwd}
    onChange={handlePwdInput}
    autoComplete="off"
    required
  />
</div>
<div className="mb-3">
  <label>Repassword</label>
  <input
    type="password"
    className="form-control"
    placeholder="Re-enter password"
    id="rePassword"
    value={rePwd}
    onChange={handleRePwdInput}
    autoComplete="off"
    required

  />
</div>
<p style={{color:'red'}} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
<p style={{color:'green'}} ref={succRef} className={succMsg ? "errmsg" : "offscreen"} aria-live="assertive">{succMsg}</p>
<div className="d-grid">
  <button type="submit" className="btn btn-primary">
    Sign Up
  </button>
</div>

</form>
      </Modal>
</>
  )
}
export default UsersList
