
import { useNavigate } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react'


import { useDispatch } from 'react-redux'
import { setCredentials } from '../slices/authSlice'
import { useForgotPasswordMutation } from '../slices/authApiSlice'

import { useSelector } from "react-redux"
import { selectCurrentToken } from "../slices/authSlice"

import logo from '../../../assets/logo.png'

const ForgotPassword = () => {
 

    const emailRef = useRef()
    const succRef = useRef()
    const [email, setEmail] = useState('')
    const [succMsg, setSuccMsg] = useState('')
    const navigate = useNavigate()

    const [forgotPassword, { isLoading }] = useForgotPasswordMutation()

    const token = useSelector(selectCurrentToken)

   

    useEffect(() => {
      if(token){
        navigate('/')
      }else{
      emailRef.current.focus()
      }
    }, [])

    useEffect(() => {
        setSuccMsg('')
    }, [email])

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
          console.log({ Email:email, Password:'' })
            const userData = await forgotPassword({ Email:email}).unwrap()
            setSuccMsg('If mail is registered, a message is sent with instruction to reset password.')
            succRef.current.focus();
            setEmail('')
        } catch (err) {
            // if (!err?.originalStatus) {
            //     // isLoading: true until timeout occurs
            //     console.log(err)
            //     setErrMsg('No Server Response');
            // } else if (err.originalStatus === 400) {
            //     setErrMsg('Missing Username or Password');
            // } else if (err.originalStatus === 401) {
            //     setErrMsg('Unauthorized');
            // } else {
            //     setErrMsg('Login Failed');
            // }
            // errRef.current.focus();
        }
    }

    const handleEmailInput = (e) => setEmail(e.target.value)

    const content = isLoading ? <h1>Loading...</h1> : (
   
      <div className="auth-wrapper">
         <div className="auth-inner">
      <section>
        <h3> <img src={logo} alt="Logo" style={{ height: 60, width: 150}} /></h3>
      <form onSubmit={handleSubmit}>
      
        <div className="mb-3">
          <label>Email address</label>
          <input
            type="email"
            id="email"
            ref={emailRef}
            value={email}
            onChange={handleEmailInput}
            autoComplete="off"
            required
            className="form-control"
            placeholder="Enter email"
          />
        </div>
        
        <p style={{color:'green'}} ref={succRef} className={succMsg ? "errmsg" : "offscreen"} aria-live="assertive">{succMsg}</p>
        <div className="d-grid">
          <button type="submit" className="btn btn-primary">
            Send email
          </button>
        </div>
    
       
      
   
      </form>
  </section>
  </div></div>
    )

    return content
}
export default ForgotPassword