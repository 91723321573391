import React from 'react'
import logo from '../../../assets/logo.png'

import { useRef, useState, useEffect, } from 'react'
import { useNavigate,useSearchParams } from 'react-router-dom'
import { useSelector } from "react-redux"
import { selectCurrentToken } from "../slices/authSlice"

import { useLocation } from 'react-router-dom'

import { useResetPasswordMutation } from '../slices/authApiSlice'

const ResetPassword = () => {
 
    // const location = useLocation()
    // const { email } = location.state
    // const { token } = location.state

  const [queryParameters] = useSearchParams()

  const email = queryParameters.get("email")
  const  token  = queryParameters.get("token")

  const pwdRef = useRef()
  const errRef = useRef()
  const succRef = useRef()
  
  const [pwd, setPwd] = useState('')
  const [rePwd, setRePwd] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [succMsg, setSuccMsg] = useState('')
  const navigate = useNavigate()

  const loginToken = useSelector(selectCurrentToken)

  const [resetPassword, { isLoading }] = useResetPasswordMutation()

  useEffect(() => {
    if(loginToken){
      navigate('/')
    }else{
        pwdRef.current.focus()
    }
    //console.log(token)
  }, [])

  useEffect(() => {
    setErrMsg('')
    setSuccMsg('')
   // console.log(email)
  }, [pwd,rePwd])

  const validate = () => {
    
    let isValid = true;

    if (pwd !== "undefined") {
          
      var pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      if (!pattern.test(pwd)) {
        isValid = false;
        setErrMsg("Password is not strong enough. Password must be 8 charahters long or more with at least one upper case letter and one special character"); 
      }
    }
    
    if (typeof pwd !== "undefined" && rePwd !== "undefined") {
        
      if (pwd != rePwd) {
        isValid = false;
        setErrMsg("Passwords don't match."); 
      }
    } 

    return isValid;
}

  const handleSubmit = async (e) => {
  e.preventDefault()

  if(validate()){
  try {
    console.log({ Email:email,Token: token ,Password:pwd })
      const passwordReset = await resetPassword({ Email:email ,Password:pwd,Token: token }).unwrap()

      if (passwordReset){
        setSuccMsg('Password reset successfully')
        succRef.current.focus();
      }
      
      
     
      setPwd('')
      setRePwd('')

  } catch (err) {
      if (!err?.originalStatus) {
          // isLoading: true until timeout occurs
          setErrMsg('No Server Response');
      } else if (err.originalStatus === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.originalStatus === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Login Failed');
      }
      errRef.current.focus();
  }
}
}


  const handlePwdInput = (e) => setPwd(e.target.value)

  const handleRePwdInput = (e) => setRePwd(e.target.value)


const content = isLoading ? <h1>Loading...</h1> : (
  
  <div className="auth-wrapper">
     <div className="auth-inner">
<section>
  <h3> <img src={logo} alt="Logo" style={{ height: 60, width: 160}} /></h3>
<form onSubmit={handleSubmit}>

    
    <div className="mb-3">
      <label>New Password</label>
      <input
        type="password"
        className="form-control"
        placeholder="Enter password"
        id="password"
        value={pwd}
        ref={pwdRef}
        onChange={handlePwdInput}
        autoComplete="off"
        required
      />
    </div>
    <div className="mb-3">
      <label>Repassword</label>
      <input
        type="password"
        className="form-control"
        placeholder="Re-enter password"
        id="rePassword"
        value={rePwd}
        onChange={handleRePwdInput}
        autoComplete="off"
        required

      />
    </div>
    <p style={{color:'red'}} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
    <p style={{color:'green'}} ref={succRef} className={succMsg ? "errmsg" : "offscreen"} aria-live="assertive">{succMsg}</p>
    {succMsg ==""?(
    <div className="d-grid">
      <button type="submit" className="btn btn-primary">
        Reset Password
      </button>
    </div>)
    :(
    <div className="d-grid">
      <button type="submit" className="btn btn-primary">
       Login
      </button>
    </div>)}
   
    </form>
  </section>
  </div></div>
)

  return  content
}

export default ResetPassword