import React from 'react'
import logo from '../../../assets/logo.png'

import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux"
import { selectCurrentToken } from "../slices/authSlice"

import { useLocation } from 'react-router-dom'

//import { useNewAccountMutation } from '../slices/authApiSlice'

const NewAccount = () => {
 
 
    const emailRef = useRef()
  //const pwdRef = useRef()
  const errRef = useRef()
  const succRef = useRef()
  
  const [email, setEmail] = useState('')
  const [oldPwd, setOldPwd] = useState('')
  const [pwd, setPwd] = useState('')
  const [rePwd, setRePwd] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [succMsg, setSuccMsg] = useState('')
  const navigate = useNavigate()

  const handleEmailInput = (e) => setEmail(e.target.value)

    const handleOldPwdInput = (e) => setOldPwd(e.target.value)


 // const [resetPassword, { isLoading }] = useNewAccountMutation()

  useEffect(() => {
    // if(loginToken){
    //   navigate('/')
    // }else{
    //     pwdRef.current.focus()
    // }
  }, [])

  useEffect(() => {
    setErrMsg('')
    setSuccMsg('')
  }, [pwd,rePwd])

  const validate = () => {
    
    let isValid = true;

    if (pwd !== "undefined") {
          
      var pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      if (!pattern.test(pwd)) {
        isValid = false;
        setErrMsg("Password is not strong enough. Password must be 8 charahters long or more with at least one upper case letter and one special character"); 
      }
    }
    
    if (typeof pwd !== "undefined" && rePwd !== "undefined") {
        
      if (pwd != rePwd) {
        isValid = false;
        setErrMsg("Passwords don't match."); 
      }
    } 

    return isValid;
}

  const handleSubmit = async (e) => {
  e.preventDefault()

  if(validate()){
  try {
    //   const passwordReset = await resetPassword({ Email:email,Token: token ,Password:pwd }).unwrap()

    //   if (passwordReset){
    //     setSuccMsg('Password reset successfully')
    //     succRef.current.focus();
    //   }
      
      
     
      setPwd('')
      setRePwd('')

  } catch (err) {
      if (!err?.originalStatus) {
          // isLoading: true until timeout occurs
          setErrMsg('No Server Response');
      } else if (err.originalStatus === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.originalStatus === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Login Failed');
      }
      errRef.current.focus();
  }
}
}


  const handlePwdInput = (e) => setPwd(e.target.value)

  const handleRePwdInput = (e) => setRePwd(e.target.value)


const content = false ? <h1>Loading...</h1> : (
  
  <div className="auth-wrapper">
     <div className="auth-inner">
<section>
  <h3> <img src={logo} alt="Logo" style={{ height: 60, width: 160}} /></h3>
<form onSubmit={handleSubmit}>

<div className="mb-3">
          <label>Email address</label>
          <input
            type="email"
            id="email"
            ref={emailRef}
            value={email}
            onChange={handleEmailInput}
            autoComplete="off"
            required
            className="form-control"
            placeholder="Enter email"
          />
        </div>
        <div className="mb-3">
          <label>Old Password</label>
          <input
             type="password"
             id="password"
             onChange={handleOldPwdInput}
             value={oldPwd}
             required
             className="form-control"
             placeholder="Enter password"
          />
        </div>
    <div className="mb-3">
      <label>New Password</label>
      <input
        type="password"
        className="form-control"
        placeholder="Enter password"
        id="password"
        value={pwd}
        onChange={handlePwdInput}
        autoComplete="off"
        required
      />
    </div>
    <div className="mb-3">
      <label>Repassword</label>
      <input
        type="password"
        className="form-control"
        placeholder="Re-enter password"
        id="rePassword"
        value={rePwd}
        onChange={handleRePwdInput}
        autoComplete="off"
        required

      />
    </div>
    <p style={{color:'red'}} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
    <p style={{color:'green'}} ref={succRef} className={succMsg ? "errmsg" : "offscreen"} aria-live="assertive">{succMsg}</p>
    <div className="d-grid">
      <button type="submit" className="btn btn-primary">
        Reset Password
      </button>
    </div>
    </form>
  </section>
  </div></div>
)

  return  content
}

export default NewAccount